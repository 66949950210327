import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/pages/Home/index.vue";
import Settings from "@/pages/settings/index.vue";
import PosSettings from "@/pages/settings/pos/index.vue";
import ServicesSetings from "@/pages/settings/service/index.vue";
import SpacesSetings from "@/pages/settings/space/index.vue";
import TeamMembersSettings from "@/pages/settings/teamMembers/index.vue";
import ClientsSettings from "@/pages/settings/clients/index.vue";
import LanguageSettings from "@/pages/settings/language/index.vue";
import ReviewsSettings from "@/pages/settings/reviews/index.vue";
import AddReservation from "@/pages/reservation/add.vue";
import EditReservation from "@/pages/reservation/edit.vue";
import ReservationList from "@/pages/reservation/list.vue";
import Login from "@/pages/Login.vue";
import axios from "@/services/axios";
import { AxiosHeaders, AxiosRequestConfig } from "axios";
import Forbidden from "@/pages/Forbidden.vue";
import { LayoutName } from "@/layouts";
import Store from "@/store";
import TermsOfUse from "@/pages/TermsOfUse.vue";
import Book from "@/pages/Book.vue";
import ConfirmBooking from "@/pages/ConfirmBooking.vue";
import CancelBooking from "@/pages/CancelBooking.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      layout: LayoutName.WithNav,
    },
  },

  {
    path: "/settings/pos",
    name: "PosSettings",
    component: PosSettings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/settings/service",
    name: "ServicesSettings",
    component: ServicesSetings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/settings/space",
    name: "SpacesSettings",
    component: SpacesSetings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/settings/team-members",
    name: "TeamSettings",
    component: TeamMembersSettings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/settings/clients",
    name: "ClientsSettings",
    component: ClientsSettings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/settings/language",
    name: "LanguageSettings",
    component: LanguageSettings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/settings/reviews",
    name: "ReviewsSettings",
    component: ReviewsSettings,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/reservation/add",
    name: "AddReservation",
    component: AddReservation,
    meta: {
      layout: LayoutName.WithNav,
    },
  },

  {
    path: "/reservation/edit/:id",
    name: "ReservationEdit",
    component: EditReservation,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/reservation/list",
    name: "ReservationList",
    component: ReservationList,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/reservation/edit/:id",
    name: "ReservationEdit",
    component: EditReservation,
    meta: {
      layout: LayoutName.WithNav,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: LayoutName.Default,
    },
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: Forbidden,
    meta: {
      layout: LayoutName.Default,
    },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: {
      layout: LayoutName.Default,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/About.vue"),
  },
  {
    path: "/book",
    name: "Book",
    component: Book,
    meta: {
      layout: LayoutName.Default,
    },
  },
  {
    path: "/confirm-booking/:id",
    name: "ConfirmBooking",
    component: ConfirmBooking,
    meta: {
      layout: LayoutName.Default,
    },
  },
  {
    path: "/cancel-booking/:id",
    name: "CancelBooking",
    component: CancelBooking,
    meta: {
      layout: LayoutName.Default,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
interface CustomHeaders extends AxiosHeaders {
  Authorization?: string;
}
// Interceptor to add Authorization header to requests
axios.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Get the jwt cookie
    const jwt = Store.getters["user/jwt"];

    // If the cookie is present, set the Authorization header
    if (jwt) {
      config.headers = config.headers || ({} as AxiosHeaders);
      (config.headers as CustomHeaders).Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  function (error) {
    // If there's an error, reject the Promise with the error
    return Promise.reject(error);
  }
);
// interceptor to redirect to the login page if we get a 401 response (unauthorized)/403 forbidden
axios.interceptors.response.use(
  (response) => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  (error) => {
    const { status } = error.response;
    // UNAUTHORIZED
    if (status === 401) {
      Store.dispatch("user/LOGOUT");
      Store.dispatch("pos/LOGOUT");
      // Redirect to the login page
      router.replace({ name: "Login" });
    }
    // FORBIDDEN
    if (status === 403) {
      // Redirect to the login page
      router.replace({ name: "Forbidden" });
    }

    // If the request fails, reject the Promise with the error
    return Promise.reject(error);
  }
);

export default router;
