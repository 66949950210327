export class DateToISOString {
  private dateToIsoString(date: Date): string {
    try {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    } catch {
      throw new Error("INVALID_DATE");
    }
  }
  private date: string;
  get value(): string {
    return this.date;
  }
  private constructor(date: Date) {
    this.date = this.dateToIsoString(date);
  }
  static create(date: Date) {
    if (!date) {
      throw new Error("DATE_IS_REQUIRED");
    }
    return new DateToISOString(date);
  }
}
