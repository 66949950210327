import axios from "./axios";
export const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const jwt = (await axios.post("user/login", { username, password })).data
    ?.access_token;
  return jwt;
};
