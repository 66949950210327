import store from "@/store";
import { CalendarReservations } from "@/types";
import { subDays } from "date-fns";
import { clone, cloneDeep } from "lodash";
import { DateTimeToISOString, isDateTimeInSlot } from "./date";

const removeDateWithoutReservations = (calendar: any) => {
  for (const [key] of Object.entries(calendar)) {
    if (calendar[key].reservations.length === 0) {
      delete calendar[key];
    }
  }
  return calendar;
};
export const countAttendees = (reservations: any) => {
  return cloneDeep(reservations).reduce((acc: number, reservation: any) => {
    return acc + reservation.attendeesCount;
  }, 0);
};
export const countReservations = (reservations: any) => {
  return cloneDeep(reservations).reduce((acc: number, reservation: any) => {
    return acc + 1;
  }, 0);
};

export const filterByStatus = ({
  reservations,
  statuses,
  includeWithoutStatus = false,
}: {
  reservations: any;
  statuses: any;
  includeWithoutStatus: boolean;
}) => {
  let result = cloneDeep(reservations);

  for (const [key] of Object.entries(result)) {
    result[key].reservations = result[key].reservations.filter(
      (reservation: any) => {
        return statuses.some((status: any) => {
          return status === reservation.status;
        });
      }
    );
  }
  if (includeWithoutStatus) {
    const withoutStatusReservations = cloneDeep(reservations);
    for (const [key] of Object.entries(withoutStatusReservations)) {
      withoutStatusReservations[key].reservations = withoutStatusReservations[
        key
      ].reservations.filter((reservation: any) => {
        return !reservation.status;
      });
    }
    for (const [key] of Object.entries(result)) {
      result[key].reservations = [
        ...result[key].reservations,
        ...withoutStatusReservations[key].reservations,
      ];
    }
  }
  //remove empty reservations
  result = removeDateWithoutReservations(result);
  for (const [key] of Object.entries(result)) {
    result[key].attendeesCount = countAttendees(result[key].reservations);
    result[key].reservationsCount = countReservations(result[key].reservations);
  }

  return sortCalendar(result);
};

export const filterBySpaces = ({
  reservations,
  spaces,
  includeWithoutSpace = false,
}: {
  reservations: any;
  spaces: any;
  includeWithoutSpace: boolean;
}) => {
  let result = cloneDeep(reservations);

  for (const [key] of Object.entries(result)) {
    result[key].reservations = result[key].reservations.filter(
      (reservation: any) => {
        return spaces.some((space: any) => {
          return space === reservation.spaceId;
        });
      }
    );
  }
  if (includeWithoutSpace) {
    const withoutSpaceReservations = cloneDeep(reservations);
    for (const [key] of Object.entries(withoutSpaceReservations)) {
      withoutSpaceReservations[key].reservations = withoutSpaceReservations[
        key
      ].reservations.filter((reservation: any) => {
        return !reservation.spaceId;
      });
    }
    for (const [key] of Object.entries(result)) {
      result[key].reservations = [
        ...result[key].reservations,
        ...withoutSpaceReservations[key].reservations,
      ];
    }
  }
  //remove empty reservations
  result = removeDateWithoutReservations(result);
  for (const [key] of Object.entries(result)) {
    result[key].attendeesCount = countAttendees(result[key].reservations);
    result[key].reservationsCount = countReservations(result[key].reservations);
  }

  return sortCalendar(result);
};
export const filterByServices = ({
  reservations,
  services,
  includeOutOfService,
}: {
  reservations: any;
  services: any;
  includeOutOfService: boolean;
}) => {
  services = (services ?? []).map((service: any) => service?.id);
  let result = cloneDeep(reservations);

  for (const [key] of Object.entries(result)) {
    result[key].reservations = result[key].reservations.filter(
      (reservation: any) => {
        const reservationInServices = (reservation?.servicesIn ?? []).map(
          (service: any) => service.id
        );
        const isInCluded = reservationInServices.some(
          (value: any) =>
            services.includes(value) && !reservation.isOutOfService
        );
        return isInCluded;
      }
    );
  }
  const outOfServiceReservations = cloneDeep(reservations);
  if (includeOutOfService) {
    for (const [key] of Object.entries(outOfServiceReservations)) {
      outOfServiceReservations[key].reservations = outOfServiceReservations[
        key
      ].reservations.filter((reservation: any) => {
        return reservation.isOutOfService;
      });
    }
    for (const [key] of Object.entries(result)) {
      result[key].reservations = [
        ...result[key].reservations,
        ...outOfServiceReservations[key].reservations,
      ];
    }
  }

  result = removeDateWithoutReservations(result);

  for (const [key] of Object.entries(result)) {
    result[key].attendeesCount = countAttendees(result[key].reservations);
    result[key].reservationsCount = countReservations(result[key].reservations);
  }

  return sortCalendar(result);
};
export function sortCalendar(calendar: any) {
  const sortedKeys = Object.keys(calendar).sort();

  const sortedData: any = {};
  sortedKeys.forEach((key: any) => {
    sortedData[key] = calendar[key];
  });
  return sortedData;
}
export const groupByServices = ({
  reservations,
  services = [],
}: {
  reservations: any;
  services: any;
}) => {
  const result = cloneDeep(reservations);

  for (const [key] of Object.entries(result)) {
    result[key].reservations = result[key].reservations.filter(
      (reservation: any) => {
        const servicesIn = services.filter((service: any) => {
          return isDateTimeInSlot(reservation.at, {
            startingAt: service.startingAt,
            endingAt: service.endingAt,
          });
        });
        reservation.servicesIn = (servicesIn ?? []).map((service: any) => ({
          id: service.id,
          name: service.name,
        }));
        return servicesIn.length > 0;
      }
    );
  }

  // get out of service reservations
  const outOfServiceReservations = cloneDeep(reservations);
  for (const [key] of Object.entries(outOfServiceReservations)) {
    outOfServiceReservations[key].reservations = outOfServiceReservations[
      key
    ].reservations
      .filter((reservation: any) => {
        const isSameDay = DateTimeToISOString(new Date(reservation.at)) === key;
        if (!isSameDay) return false;
        const servicesExLimitedToEndOfDay = cloneDeep(services).map(
          (service: any) => {
            if (service.isExpandingtoNextDay) {
              service.endingAt = "23:59";
            }
            return service;
          }
        );
        const notInServices = servicesExLimitedToEndOfDay.every(
          (service: any) => {
            return !isDateTimeInSlot(reservation.at, {
              startingAt: service.startingAt,
              endingAt: service.endingAt,
            });
          }
        );

        return notInServices;
      })
      .map((reservation: any) => {
        reservation.isOutOfService = true;
        return reservation;
      });
  }

  for (const [key] of Object.entries(result)) {
    result[key].reservations = [
      ...result[key].reservations,
      ...outOfServiceReservations[key].reservations,
    ];
  }

  return result;
};

export const groupByDate = (rawReservations: any, services: any) => {
  let calendar: CalendarReservations = {};

  for (const reservation of rawReservations) {
    const date = DateTimeToISOString(new Date(reservation.at));
    if (!calendar[date]) {
      calendar[date] = {
        attendeesCount: 0,
        reservationsCount: 0,
        reservations: [],
      };
    }
    calendar[date].reservations.push(reservation);
  }
  const servicesExpandingToNextDay = services.filter((service: any) => {
    return service.isExpandingtoNextDay;
  });

  const dates = Object.keys(calendar);
  for (const [index, key] of dates.entries()) {
    const date = key;
    const reservationsOfPrevieousDay = cloneDeep(
      calendar[date].reservations
    ).filter((reservation: any) => {
      const isPrevDay = servicesExpandingToNextDay.some((service: any) => {
        return isDateTimeInSlot(reservation.at, {
          startingAt: "00:00",
          endingAt: service.endingAt,
        });
      });
      return isPrevDay;
    });
    //remove found reservations of previous day
    reservationsOfPrevieousDay.forEach((reservation) => {
      const index = calendar[date].reservations.findIndex(
        (obj) => obj.id === reservation.id
      );
      if (index !== -1) {
        calendar[date].reservations.splice(index, 1);
      }
    });

    const previousDay = DateTimeToISOString(subDays(new Date(date), 1));
    calendar[previousDay] = {
      attendeesCount: 0,
      reservationsCount: 0,
      reservations: [
        ...(calendar[previousDay]?.reservations ?? []),
        ...reservationsOfPrevieousDay,
      ],
    };
  }
  calendar = groupByServices({ reservations: calendar, services });

  for (const [key] of Object.entries(calendar)) {
    calendar[key].attendeesCount = countAttendees(calendar[key].reservations);
    calendar[key].reservationsCount = countReservations(
      calendar[key].reservations
    );
  }

  return sortCalendar(calendar);
};
