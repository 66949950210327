import { TeamMemberId } from "@/types";
import axios from "axios";

const formatPosAccess = (posAccess: any) => {
  const r = Object?.keys(posAccess || {})
    .map((key: any) => {
      return {
        id: key,
        role: posAccess[key],
      };
    })
    .filter((access: any) => access.role);
  return r;
};
export const createTeamMember = (options: { teamMember: any }) => {
  const { username, firstName, lastName, password } = options.teamMember;
  const posAccess = formatPosAccess(options?.teamMember?.posAccess || {});
  return axios
    .post(`/team-member`, {
      username,
      firstName,
      lastName,
      posAccess,
      password,
    })
    .then(() => {
      return true;
    });
};
export const updateTeamMember = (options: { teamMember: any }) => {
  const { id, username, firstName, lastName, password } = options.teamMember;
  const posAccess = formatPosAccess(options?.teamMember?.posAccess || {});
  return axios
    .put(`/team-member/${id}`, {
      username,
      firstName,
      lastName,
      posAccess,
      password,
    })
    .then(() => {
      return true;
    });
};
export const deleteTeamMember = (options: { teamMemberId: TeamMemberId }) => {
  const { teamMemberId } = options;
  return axios.delete(`/team-member/${teamMemberId}`).then(() => {
    return true;
  });
};
