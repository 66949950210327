import { cloneDeep } from "lodash";
import axios from "./axios";
export const getById = async ({ tenantId }: { tenantId: string }) => {
  const tenant = axios.get(`tenant/${tenantId}`).then(({ data }) => {
    data?.teamMembers?.map((teamMember: any) => {
      const posAccess = cloneDeep(teamMember?.posAccess ?? []);
      teamMember.posAccess = {};
      posAccess.forEach((access: any) => {
        teamMember.posAccess[access.id] = access.role;
      });
    });

    return data;
  });
  return tenant;
};

export const getClientReviews = async ({
  tenantId,
  posIds = [],
  startDate,
  endDate,
  page,
  pageSize,
}: {
  tenantId: string;
  posIds: string[];
  startDate?: string;
  endDate?: string;
  page?: number | string;
  pageSize?: number | string;
}) => {
  const reviews = axios
    .get(`reservation/reviews/${tenantId}`, {
      params: {
        posId: posIds,
        startDate,
        endDate,
        page,
        pageSize,
      },
    })
    .then(({ data }) => {
      return (
        data ?? {
          averageRating: undefined,
          data: [],
          totalCount: 0,
          pageSize: 0,
          currentPage: 0,
          pageCount: 0,
        }
      );
    });
  return reviews;
};
