import { createSpace, deleteSpace, updateSpace } from "@/services/space";
import { Table, Space, findErrorCode } from "@/types";
import useVuelidate from "@vuelidate/core";
import { required, minValue, helpers } from "@vuelidate/validators";
import { ActionTree, GetterTree } from "vuex";

const rulesToCreateASpace = {
  name: {
    required,
  },
};

const rulesToCreateATable = {
  name: { required },
  maximumCapacity: {
    required,
    minValue: minValue(0),
  },
};
const rulesToUpdateATableList = {
  $each: helpers.forEach(rulesToCreateATable),
};
const state = {};
type State = typeof state;

// const mutations = <MutationTree<State>>(<unknown>{

// });

const actions = <ActionTree<State, unknown>>{
  async CREATE_SPACE({ rootGetters, state }, payload: any) {
    try {
      const rulesToCreateASpace = getters["rulesToCreateASpace"];
      const spaceV$ = useVuelidate(rulesToCreateASpace, payload);
      const rulesToUpdateATableList = getters["rulesToUpdateATableList"];
      const tableListV$ = useVuelidate(rulesToUpdateATableList, payload.tables);
      const isSpaceValid = await spaceV$.value.$validate();
      const isTableListValid = await tableListV$.value.$validate();
      if (!isSpaceValid || !isTableListValid) {
        return;
      }
      const tables = (payload.tables ?? []).map((rawTable: any) => {
        return new Table({
          id: rawTable.id,
          name: rawTable.name,
          maximumCapacity: rawTable.maximumCapacity,
        });
      });
      const posId = rootGetters["pos/currentPosId"];
      const space = new Space({ id: payload.id, name: payload.name, tables });
      await createSpace({ posId, space });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      throw new Error(errorCode);
    }
  },
  async UPDATE_SPACE({ rootGetters, state }, payload: any) {
    try {
      const rulesToUpdateASpace = getters["rulesToUpdateASpace"];
      const spaceV$ = useVuelidate(rulesToUpdateASpace, payload);
      const rulesToUpdateATableList = getters["rulesToUpdateATableList"];
      const tableListV$ = useVuelidate(rulesToUpdateATableList, payload.tables);
      const isSpaceValid = await spaceV$.value.$validate();
      const isTableListValid = await tableListV$.value.$validate();
      if (!isSpaceValid || !isTableListValid) {
        return;
      }
      const tables = (payload.tables ?? []).map((rawTable: any) => {
        return new Table({
          id: rawTable.id,
          name: rawTable.name,
          maximumCapacity: rawTable.maximumCapacity,
        });
      });
      const posId = rootGetters["pos/currentPosId"];
      const space = new Space({ id: payload.id, name: payload.name, tables });
      await updateSpace({ posId, space });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async DELETE_SPACE({ rootGetters, state }, payload: any) {
    try {
      const posId = rootGetters["posId"];

      const tables = (payload.tables ?? []).map((rawTable: any) => {
        return new Table({
          id: rawTable.id,
          name: rawTable.name,
          maximumCapacity: rawTable.maximumCapacity,
        });
      });

      const space = new Space({
        id: payload.id,
        name: payload.name,
        tables,
      });

      await deleteSpace({ posId, space });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToCreateASpace() {
    return rulesToCreateASpace;
  },
  rulesToUpdateASpace() {
    return rulesToCreateASpace;
  },
  rulesToCreateATable() {
    return rulesToCreateATable;
  },
  rulesToUpdateATableList() {
    return rulesToUpdateATableList;
  },
};

export default {
  namespaced: true,
  state,
  // mutations,
  actions,
  getters,
};
