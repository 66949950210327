import axios from "./axios";
import { ISOTime } from "@/assets/utils/ISOTime";
import {
  Service,
  ServiceId,
  ServiceName,
  Space,
  SpaceId,
  Table,
  TeamMember,
  Pos,
  PosId,
  TenantId,
} from "@/types";
import { extractFieldsFromPayload } from "./service";

export const getPosListOfTenant = () => {
  return axios.get("/pos/list/of-tenant").then(({ data }) => {
    return data;
  });
};
export const updatePos = ({ pos }: { pos: any }) => {
  const { id, name, onlineName } = pos;
  return axios.put(`/pos/${id}`, { name, onlineName }).then(({ data }) => {
    return data;
  });
};
export const getPos = (options: { posId: PosId; tenantId: TenantId }) => {
  return axios
    .get(`/pos/${options.posId}`, { params: { tenantId: options.tenantId } })
    .then(({ data }) => {
      const services: Service[] = (data.services ?? []).map(
        (rawService: any) => {
          const service = new Service({
            id: rawService.id as ServiceId,
            name: rawService.name as ServiceName,
            startingAt: rawService.startingAt
              ? ISOTime.create(rawService.startingAt)
              : undefined,
            endingAt: rawService.endingAt
              ? ISOTime.create(rawService.endingAt)
              : undefined,
            delayBetweenSlots: rawService.delayBetweenSlots
              ? ISOTime.create(rawService.delayBetweenSlots)
              : undefined,
            spaces: (rawService.spaces ?? []).map((space: any) => ({
              id: space.id,
              name: space.name,
            })),
            onlineName: rawService.onlineName,
            averageDuration: rawService.averageDuration
              ? ISOTime.create(rawService.averageDuration)
              : undefined,
            onlineAvailablePlaces: rawService.onlineAvailablePlaces,
            openDays: rawService.openDays ?? [],
          });
          return service;
        }
      );
      const spaces = (data.spaces ?? []).map((rawSpace: any) => {
        const tables = (rawSpace.tables ?? []).map((rawTable: any) => {
          return new Table({
            id: rawTable.id,
            name: rawTable.name,
            maximumCapacity: parseInt(rawTable.maximumCapacity),
          });
        });
        const space = new Space({
          id: rawSpace.id as SpaceId,
          name: rawSpace.name,
          tables,
        });
        return space;
      });
      const teamMembers = (data.teamMembers ?? []).map(
        (teamMember: any) =>
          new TeamMember({ id: teamMember.id, fullName: teamMember.fullName })
      );
      const pos = new Pos({
        id: data.id as PosId,
        services,
        spaces,
        teamMembers,
      });
      return pos;
    });
};
