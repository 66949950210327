import { ActionTree, GetterTree, MutationTree } from "vuex";

const state = { isLoading: false };
type State = typeof state;

const mutations = <MutationTree<State>>(<unknown>{
  setIsLoading(state: State, payload: boolean) {
    state.isLoading = payload;
  },
});

const actions = <ActionTree<State, unknown>>{
  async IS_LOADING({ commit }, payload: boolean) {
    commit("setIsLoading", payload);
  },
};
const getters = <GetterTree<State, unknown>>{
  isLoading(state) {
    return state.isLoading;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
