import { cloneDeep, times } from "lodash";
import { ISOTime } from "./assets/utils/ISOTime";

export type Nullable<T> = undefined | null | T;
export type AsyncErrorCode = string | "ERROR";
export type AsyncStatus = "idle" | "loading" | "done" | "error";
export type AsyncError = { code: AsyncErrorCode; message: Nullable<string> };

export type Id = string;
export type SpaceId = Id;
export type ServiceId = Id;
export type ServiceName = string;
export type SpaceName = string;
export type TeamMemberId = string;
export type TeamMemberFullName = string;
export enum SUPPORTED_ERROR_CODES {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  INVALID_SERVICE = "INVALID_SERVICE",
  SERVICE_NOT_FOUND = "SERVICE_NOT_FOUND",
  INVALID_TABLE_MAXIMUM_CAPACITY = "INVALID_TABLE_MAXIMUM_CAPACITY",
  INVALID_DATA = "INVALID_DATA",
}
export const findErrorCode = (options: { errorCode: string }) => {
  if (!options || !options.errorCode) {
    return SUPPORTED_ERROR_CODES.UNKNOWN_ERROR;
  }

  const isValidCode = options.errorCode in SUPPORTED_ERROR_CODES;

  return isValidCode ? options.errorCode : SUPPORTED_ERROR_CODES.UNKNOWN_ERROR;
};

interface ServiceProps {
  id?: ServiceId;
  name: ServiceName;
  startingAt?: ISOTime;
  endingAt?: ISOTime;
  delayBetweenSlots?: ISOTime;
  spaces: { id: SpaceId; name: SpaceName }[];
  onlineName: string;
  averageDuration?: ISOTime;
  onlineAvailablePlaces: number;
  openDays: string[];
}
export class Service {
  constructor(private props: ServiceProps) {
    this.props.spaces = this.props.spaces ?? [];
  }
  getSpaces() {
    return cloneDeep(this.props.spaces);
  }
  getId() {
    return this.props.id;
  }
  getName() {
    return this.props.name;
  }
  getStartingAt() {
    return cloneDeep(this.props.startingAt);
  }
  getEndingAt() {
    return cloneDeep(this.props.endingAt);
  }
  getDuration() {
    return this.props.endingAt
      ? this.props.startingAt?.diff(this.props?.endingAt)
      : undefined;
  }

  isExpandingtoNextDay() {
    return this.props.startingAt && this.props.endingAt
      ? this.props.startingAt?.isGivenTimeInNextDay(this.props.endingAt)
      : false;
  }
  get onlineName() {
    return this.props.onlineName;
  }
  get averageDuration() {
    return this.props.averageDuration;
  }
  get onlineAvailablePlaces() {
    return this.props.onlineAvailablePlaces;
  }
  get openDays() {
    return this.props.openDays ?? [];
  }
  get delayBetweenSlots() {
    return this.props.delayBetweenSlots;
  }
}
export type TableId = Id;
export interface TableProps {
  id?: TableId;
  name: string;
  maximumCapacity: number;
}
export class Table {
  constructor(private props: TableProps) {}
  getId() {
    return this.props.id;
  }
  getName() {
    return this.props.name;
  }
  getMaximumCapacity() {
    return this.props.maximumCapacity ?? 0;
  }
}
interface SpaceProps {
  id?: SpaceId;
  name: string;
  tables: Table[];
}
export class Space {
  constructor(private props: SpaceProps) {}
  getId() {
    return this.props.id;
  }
  getName() {
    return this.props.name;
  }
  getTables() {
    return this.props.tables ?? [];
  }
}
export type TenantId = Id;
export type PosId = Id;
export type PosName = string;

interface TeamMemberProps {
  id?: TeamMemberId;
  fullName: TeamMemberFullName;
}
export class TeamMember {
  constructor(private props: TeamMemberProps) {}
  getId() {
    return this.props.id;
  }
  getFullName() {
    return this.props.fullName;
  }
}
interface PosProps {
  id: PosId;
  services: Service[];
  spaces: Space[];
  teamMembers: TeamMember[];
}
export class Pos {
  constructor(private props: PosProps) {}
  getServices() {
    return this.props.services ?? [];
  }
  findService(serviceId: ServiceId) {
    return this.getServices().find((service) => service.getId() === serviceId);
  }
  getSpaces() {
    return this.props.spaces ?? [];
  }
  getTeamMembers() {
    return this.props.teamMembers;
  }
}
type ReservationId = Id;
export enum RESERVATION_STATUS {
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION",
  CONFIRMED = "CONFIRMED",
  VALIDATED = "VALIDATED",
  // DONE = "DONE",
  CANCELED = "CANCELED",
  NO_SHOW = "NO_SHOW",
}
export enum GENDER {
  FEMALE = "FEMALE",
  MALE = "MALE",
}
export enum RESERVATION_CHANNEL {
  RESERVATION_CENTER = "RESERVATION_CENTER",
  MANAGER = "MANAGER",
  DIRECT = "DIRECT",
  SALES = "SALES",
  EXTERNAL_CONCIERGE = "EXTERNAL_CONCIERGE",
  SOCIAL_NETWORKS = "SOCIAL_NETWORKS",
  ONLINE = "ONLINE",
}
export type ClientId = Id;
export type CalendarReservations = {
  [key: string]: {
    attendeesCount: number;
    reservationsCount: number;
    reservations: any[];
  };
};
export type ReservationProps = {
  id?: ReservationId;
  at: string;
  status: RESERVATION_STATUS;
  channel: RESERVATION_CHANNEL;
  spaceId?: SpaceId;
  tableIds?: TableId[];
  client?: any;
  attendeesCount: number;
  comment: string;
  teamMemberId: TeamMemberId;
  rating?: {
    rating: number | string | null;
    comment?: string;
    atmosphere?: { rating: number | string | null; comment: string };
    service?: { rating: number | string | null; comment: string };
    food?: { rating: number | string | null; comment: string };
    qualityPriceRatio?: { rating: number | string | null; comment: string };
    at: string;
  };
  amount?: string;
};

export class Reservation {
  constructor(private props: ReservationProps) {}
  get id() {
    return this.props.id!;
  }
  set id(id: ReservationId) {
    this.props.id = id;
  }
  get at() {
    return this.props.at;
  }
  set at(at: string) {
    this.props.at = at;
  }
  get status() {
    return this.props.status;
  }

  set status(status: RESERVATION_STATUS) {
    this.props.status = status;
  }
  get amount() {
    return this.props.amount;
  }
  set amount(amount: any) {
    this.props.amount = amount;
  }
  get channel() {
    return this.props.channel;
  }
  set channel(channel: RESERVATION_CHANNEL) {
    this.props.channel = channel;
  }
  get spaceId() {
    return this.props.spaceId!;
  }
  set spaceId(spaceId: SpaceId) {
    this.props.spaceId = spaceId;
  }
  get client() {
    return this.props.client!;
  }
  set client(client: any) {
    this.props.client = client;
  }
  get attendeesCount() {
    return this.props.attendeesCount;
  }
  set attendeesCount(attendeesCount: number) {
    this.props.attendeesCount = attendeesCount;
  }
  get tableIds() {
    return this.props.tableIds!;
  }
  set tableId(tableIds: TableId[]) {
    this.props.tableIds = tableIds;
  }
  get comment() {
    return this.props.comment;
  }

  set comment(comment: string) {
    this.props.comment = comment;
  }
  get teamMemberId() {
    return this.props.teamMemberId;
  }
  set teamMemberId(teamMemberId: TeamMemberId) {
    this.props.teamMemberId = teamMemberId;
  }
  get rating() {
    return this.props.rating;
  }
}

export interface ClientProps {
  id?: ClientId;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber: string;
}
export class Client {
  constructor(private props: ClientProps) {}
  get id() {
    return this.props.id!;
  }
  set id(id: ClientId) {
    this.props.id = id;
  }
  get firstName() {
    return this.props.firstName;
  }
  set firstName(firstName: string) {
    this.props.firstName = firstName;
  }
  get lastName() {
    return this.props.lastName;
  }
  set lastName(lastName: string) {
    this.props.lastName = lastName;
  }
  get email() {
    return this.props.email!;
  }
  set email(email: string) {
    this.props.email = email;
  }
  get phoneNumber() {
    return this.props.phoneNumber;
  }
}
