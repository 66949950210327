import { GENDER, RESERVATION_CHANNEL, RESERVATION_STATUS } from "@/types";
import { ComposerTranslation } from "vue-i18n";

export const genderList = (t: ComposerTranslation) => {
  return Object.keys(GENDER).map((key: string) => ({
    id: key,
    name: t(`${key}`),
  }));
};

export const reservationStatusList = (t: ComposerTranslation) => {
  return Object.keys(RESERVATION_STATUS).map((key: string) => ({
    id: key,
    name: t(`${key}`),
  }));
};

export const channelsList = (t: ComposerTranslation) => {
  return Object.keys(RESERVATION_CHANNEL)?.map((key: string) => ({
    id: key,
    name: t(`${key}`),
  }));
};
