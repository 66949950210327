import {
  Reservation,
  RESERVATION_STATUS,
  PosId,
  RESERVATION_CHANNEL,
} from "@/types";
import axios from "./axios";

const mapReservation = (rawReservation: any) => {
  const reservation = {
    id: rawReservation.id,
    at: new Date(rawReservation.at),
    status: rawReservation.status as RESERVATION_STATUS,
    channel: rawReservation.channel as RESERVATION_CHANNEL,
    spaceId: rawReservation.spaceId,
    tableIds: rawReservation.tableIds,
    isOnline: rawReservation.isOnline,
    client: rawReservation.client,
    createdAt: rawReservation.createdAt,
    createdBy: rawReservation.createdBy,
    updateHistory: rawReservation.updateHistory,
    attendeesCount: parseInt(rawReservation.attendeesCount),
    comment: rawReservation.comment,
    rating: rawReservation.rating,
    amount: rawReservation.amount,
  };
  return reservation as any;
};
export const cancelBooking = (options: { id: string }) => {
  const { id } = options;
  return axios.put(`/reservation/cancel/${id}`);
};
export const confirmBooking = (options: { id: string }) => {
  const { id } = options;
  return axios.put(`/reservation/confirm/${id}`);
};
export const addReservation = (options: {
  reservation: Reservation;
  posId: PosId;
  notifyClient?: boolean;
}) => {
  const { reservation } = options;
  return axios.post("/reservation", {
    at: reservation.at,
    posId: options.posId,
    status: reservation.status,
    spaceId: reservation.spaceId,
    tableIds: reservation.tableIds,
    client: reservation.client,
    attendeesCount: reservation.attendeesCount,
    comment: reservation.comment,
    teamMemberId: reservation.teamMemberId,
    notifyClient: options.notifyClient,
    channel: reservation.channel,
    amount: reservation.amount,
  });
};
export const changeReservationDetails = (options: {
  reservation: Reservation;
  posId: PosId;
  notifyClient?: boolean;
}) => {
  const { reservation } = options;
  return axios.put(`/reservation/${reservation.id}`, {
    at: reservation.at,
    posId: options.posId,
    status: reservation.status,
    spaceId: reservation.spaceId,
    tableIds: reservation.tableIds,
    client: reservation.client,
    attendeesCount: reservation.attendeesCount,
    comment: reservation.comment,
    teamMemberId: reservation.teamMemberId,
    channel: reservation.channel,
    notifyClient: options.notifyClient,
    amount: reservation.amount,
  });
};
export const downloadReservations = (options: {
  startDate: string;
  endDate?: string;
  posId: string;
}) => {
  const { startDate, endDate, posId } = options;
  return axios
    .get("/reservation/download", {
      params: { startDate, endDate, posId },
    })
    .then(({ data }) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    });
};
export const getReservations = (options: {
  startDate: string;
  endDate?: string;
  posId: string;
}) => {
  const { startDate, endDate, posId } = options;
  return axios
    .get("/reservation", {
      params: { startDate, endDate, posId },
    })
    .then(({ data }) => {
      return data.map((rawReservation: any) =>
        mapReservation(rawReservation)
      ) as Promise<Reservation[]>;
    });
};
export const getReservation = (options: { id: string; posId: string }) => {
  const { id, posId } = options;
  return axios
    .get(`/reservation/${id}`, {
      params: { posId },
    })
    .then(({ data: rawReservation }) => {
      return mapReservation(rawReservation);
    }) as Promise<Reservation>;
};
