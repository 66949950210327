import { getMonthStartAndEnd } from "@/assets/utils/date";
import { book, getAvailabilities, getOnlinePos } from "@/services/online";
import { PosId, ServiceId } from "@/types";
import { ActionTree, GetterTree } from "vuex";

const state = {};
type State = typeof state;
const actions = <ActionTree<State, unknown>>{
  async RETRIEVE_POS(
    { state },
    { posId, serviceIds = [] }: { posId: string; serviceIds: string[] }
  ) {
    const pos = await getOnlinePos({ posId, serviceIds });
    return pos;
  },
  async RETRIEVE_AVAILABILITIES(
    { state },
    {
      month,
      year,
      posId,
      serviceId,
    }: {
      month: number;
      year: number;
      posId: PosId;
      serviceId: ServiceId;
    }
  ) {
    const { startOfMonth, endOfMonth } = getMonthStartAndEnd(month, year);
    const availabilities = await getAvailabilities({
      posId,
      serviceId,
      startDate: startOfMonth,
      endDate: endOfMonth,
    });
    return availabilities;
  },
  async BOOK(_, payload: any) {
    await book(payload);
  },
};
const getters = <GetterTree<State, unknown>>{};
export default {
  namespaced: true,
  state,
  // mutations,
  actions,
  getters,
};
