import { ServiceId, PosId } from "@/types";
import axios from "./axios";
export const getOnlinePos = ({
  posId,
  serviceIds,
}: {
  posId: PosId;
  serviceIds: ServiceId[];
}) => {
  return axios
    .get(`/pos/online/${posId}`, {
      params: { serviceIds },
    })
    .then(({ data }) => {
      return data;
    });
};

export const book = (payload: any) => {
  return axios.post(`/reservation/online-reservation`, payload);
};

export const getAvailabilities = ({
  startDate,
  endDate,
  posId,
  serviceId,
}: {
  startDate: string;
  endDate: string;
  posId: PosId;
  serviceId: ServiceId;
}) => {
  return axios
    .get(`/pos/online-availabilities/${posId}`, {
      params: { startDate, endDate, serviceId },
    })
    .then(({ data }) => {
      return new Map(Object.entries(data ?? {}));
    });
};
