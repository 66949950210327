import { Service, PosId } from "@/types";

import axios from "./axios";
import { ISOTime } from "@/assets/utils/ISOTime";

export const extractFieldsFromPayload = (payload: any) => {
  const startingAtParsed = payload.startingAt
    ? ISOTime.create(payload.startingAt).value
    : undefined;
  const endingAtParsed = payload.endingAt
    ? ISOTime.create(payload.endingAt).value
    : undefined;
  const averageDuration = payload.averageDuration
    ? ISOTime.create(payload.averageDuration).value
    : undefined;
  const delayBetweenSlots = payload.delayBetweenSlots
    ? ISOTime.create(payload.delayBetweenSlots).value
    : undefined;
  return {
    id: payload.id,
    name: payload.name,
    startingAt: startingAtParsed,
    endingAt: endingAtParsed,
    delayBetweenSlots,
    spaceIds: (payload.spaces ?? []).map((space: any) => space.id),
    onlineName: payload.onlineName,
    averageDuration,
    onlineAvailablePlaces: payload.onlineAvailablePlaces,
    openDays: payload.openDays ?? [],
  };
};

export const createService = (options: { posId: string; service: any }) => {
  const { service, posId } = options;
  return axios
    .post(`/service`, {
      ...extractFieldsFromPayload(service),
      posId,
    })
    .then(() => {
      return true;
    });
};

export const updateService = (options: { posId: string; service: any }) => {
  const { service, posId } = options;
  return axios
    .put(`/service/${service.id}`, {
      ...extractFieldsFromPayload(service),
      posId,
    })
    .then(() => {
      return true;
    });
};

export const deleteService = (options: { posId: PosId; service: any }) => {
  const { service, posId } = options;
  return axios
    .delete(`/service/${service.id}`, { params: { posId } })
    .then(() => {
      return true;
    });
};
