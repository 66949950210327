import { ActionTree, GetterTree, MutationTree } from "vuex";
import { getById, getClientReviews } from "@/services/tenant";
import { findErrorCode } from "@/types";

const state = { tenant: undefined as any };
type State = typeof state;

const mutations = <MutationTree<State>>(<unknown>{
  setTenant(state: State, tenant: any) {
    state.tenant = tenant;
  },
});

const actions = <ActionTree<State, unknown>>{
  async RETRIEVE({ commit }, { tenantId }: { tenantId: string }) {
    const tenant = await getById({ tenantId });
    commit("setTenant", tenant);
    return tenant;
  },
  async RETRIEVE_REVIEWS(
    { commit, rootGetters },
    { page, posIds, startDate, endDate, pageSize }: any
  ) {
    const tenantId = rootGetters["user/info"]?.tenantId;
    try {
      const clients = await getClientReviews({
        tenantId,
        page,
        posIds,
        startDate,
        endDate,
        pageSize,
      });
      return clients;
    } catch (e: any) {
      console.log(e);
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      return errorCode;
    }
  },
};
const getters = <GetterTree<State, unknown>>{
  roles() {
    return ["agent", "validator"];
  },
  info(state: State) {
    return state.tenant;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
