//https://itnext.io/3-ways-to-create-a-multiple-layouts-system-vue-3-b24c0736b7bb

import Default from "./Default.vue";
import WithNav from "./WithNav.vue";
export enum LayoutName {
  Default = "Default",
  WithNav = "WithNav",
}

export const layouts = {
  [LayoutName.Default]: Default,
  [LayoutName.WithNav]: WithNav,
};
