import { useStore } from "vuex";

export default async function Bootstrap() {
  const store = useStore();
  if (!store.getters["user/info"]) {
    return;
  }
  store.dispatch("app/IS_LOADING", true);
  const firstPosId = (store.getters["pos/userAccess"] ?? [])[0]?.id;
  if (!store.getters["pos/currentPosId"] && firstPosId) {
    store.dispatch("pos/SET_CURRENT_POS_ID", firstPosId);
  }
  if (!store.getters["pos/pos"]) {
    await store.dispatch("pos/RETRIEVE");
  }
  store.dispatch("app/IS_LOADING", false);
}
