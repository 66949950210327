import { ISOTime } from "@/assets/utils/ISOTime";
import { TenantId, Client, ClientId } from "@/types";
import { template } from "lodash";
import axios from "./axios";

export const addClient = (options: { tenantId: TenantId; client: Client }) => {
  const { client, tenantId } = options;
  return axios
    .post(`/client`, {
      tenantId,
      firstName: client.firstName,
      lastName: client.lastName,
      phoneNumber: client.phoneNumber,
      email: client.email,
    })
    .then(({ data }) => {
      return data;
    });
};

export const findClients = (options: { tenantId: TenantId; word: string }) => {
  const { word, tenantId } = options;
  return axios
    .get(`/client/find`, {
      params: {
        tenantId,
        word,
      },
    })
    .then(({ data }) => {
      return data ?? [];
    });
};
export const listClients = (options: {
  tenantId: TenantId;
  word: string;
  page: number;
}) => {
  const { word, tenantId, page } = options;
  return axios
    .get(`/client/list`, {
      params: {
        tenantId,
        word,
        page,
      },
    })
    .then(({ data }) => {
      return (
        data ?? {
          data: [],
          totalCount: 0,
          pageSize: 0,
          currentPage: 0,
          pageCount: 0,
        }
      );
    });
};

export const getClientById = (options: {
  tenantId: TenantId;
  clientId: string;
}) => {
  const { clientId, tenantId } = options;
  return axios
    .get(`/client/${clientId}`, {
      params: {
        tenantId,
      },
    })
    .then(({ data }) => {
      return data;
    });
};

export const deleteClient = (options: { id: ClientId }) => {
  const { id } = options;
  return axios.delete(`/client/${id}`).then(({ data }) => {
    return data;
  });
};

export const updateClient = ({
  client,
  tenantId,
}: {
  client: any;
  tenantId: TenantId;
}) => {
  return axios
    .put(`/client/${client.id}`, { ...client, tenantId })
    .then(({ data }) => {
      return data;
    });
};
