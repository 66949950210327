<template>
  <section class="flex flex-col gap-y-8 p-4">
    <div
      class="rounded bg-red-200 flex flex-col gap-y-8 p-8 justify-center items-center"
    >
      <h1 class="font-bold text-2xl text-center">{{ $t("forbidden") }}</h1>
      <p>{{ $t("forbiddenDueToLackOfRights") }}</p>
    </div>
  </section>
</template>
