import {
  createTeamMember,
  deleteTeamMember,
  updateTeamMember,
} from "@/services/teamMember";
import { findErrorCode } from "@/types";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { ActionTree, GetterTree } from "vuex";

const rulesToCreateATeamMember = {
  firstName: {
    required,
  },
  lastName: {
    required,
  },
  username: {
    email,
    required,
  },
  password: {
    required,
  },
};
const rulesToUpdateATeamMember = {
  id: { required },
  ...rulesToCreateATeamMember,
};
const state = {};
type State = typeof state;

// const mutations = <MutationTree<State>>(<unknown>{

// });

const actions = <ActionTree<State, unknown>>{
  async CREATE_TEAM_MEMBER({ getters }, payload: any) {
    try {
      const rulesToCreateATeamMember = getters["rulesToCreateATeamMember"];
      const newTeamMemberV$ = useVuelidate(rulesToCreateATeamMember, payload);
      const isNewTeamMemberValid = await newTeamMemberV$.value.$validate();

      if (!isNewTeamMemberValid) {
        return;
      }

      await createTeamMember({ teamMember: payload });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async UPDATE_TEAM_MEMBER({ getters }, payload: any) {
    try {
      const rulesToUpdateATeamMember = getters["rulesToUpdateATeamMember"];
      const teamMemberV$ = useVuelidate(rulesToUpdateATeamMember, payload);
      const isTeamMemberValid = await teamMemberV$.value.$validate();
      if (!isTeamMemberValid) {
        return;
      }
      await updateTeamMember({ teamMember: payload });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async DELETE_TEAM_MEMBER({ getters, state }, payload: { id: string }) {
    try {
      await deleteTeamMember({ teamMemberId: payload.id });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      throw new Error(errorCode);
    }
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToUpdateATeamMember() {
    return rulesToUpdateATeamMember;
  },
  rulesToCreateATeamMember() {
    return rulesToCreateATeamMember;
  },
};

export default {
  namespaced: true,
  state,
  // mutations,
  actions,
  getters,
};
