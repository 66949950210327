interface IsoTimeProps {
  value: string;
}

export class ISOTime {
  get value() {
    return this.props.value;
  }
  get hours() {
    return this.props.value.split(":")[0];
  }
  get minutes() {
    return this.props.value.split(":")[1];
  }
  addTime(time: ISOTime) {
    const hoursToAdd = parseInt(time.hours);
    const minutesToAdd = parseInt(time.minutes);
    const hours = parseInt(this.hours);
    const minutes = parseInt(this.minutes);
    // Add the hours and minutes together
    let totalMinutes = minutes + minutesToAdd;
    let totalHours = hours + hoursToAdd;

    // Handle any overflow of minutes
    if (totalMinutes >= 60) {
      totalMinutes -= 60;
      totalHours += 1;
    }

    // Handle any overflow of hours
    if (totalHours >= 24) {
      totalHours -= 24;
    }

    // Convert the hours and minutes back to strings
    let finalHours = totalHours.toString();
    let finalMinutes = totalMinutes.toString();

    // Add leading zeroes if needed
    if (finalHours.length === 1) {
      finalHours = "0" + finalHours;
    }
    if (finalMinutes.length === 1) {
      finalMinutes = "0" + finalMinutes;
    }

    // Return the final time as a string
    const result = finalHours + ":" + finalMinutes;
    return ISOTime.create(result);
  }
  isGivenTimeInNextDay(time: ISOTime) {
    const startDate = new Date();
    startDate.setHours(+this.hours);
    startDate.setMinutes(+this.minutes);
    const endDate = new Date();
    endDate.setHours(+time.hours);
    endDate.setMinutes(+time.minutes);
    return endDate < startDate;
  }
  diff(time: ISOTime) {
    const hours = parseInt(this.hours);
    const minutes = parseInt(this.minutes);
    const diffHours = parseInt(time.hours);
    const diffMinutes = parseInt(time.minutes);

    // Calculate the difference in hours and minutes
    const totalMinutes = (diffMinutes - minutes + 60) % 60;
    const totalHours = (diffHours - hours + 24) % 24;

    // Convert the hours and minutes back to strings
    let finalHours = totalHours.toString();
    let finalMinutes = totalMinutes.toString();

    // Add leading zeroes if needed
    if (finalHours.length === 1) {
      finalHours = "0" + finalHours;
    }
    if (finalMinutes.length === 1) {
      finalMinutes = "0" + finalMinutes;
    }

    // Return the final time as a string
    const result = finalHours + ":" + finalMinutes;
    return ISOTime.create(result);
  }

  private constructor(private props: IsoTimeProps) {}

  public static create(value: string) {
    const isValidValue = /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
    if (!isValidValue) {
      throw new Error("INVALID_TIME_VALUE");
    }
    return new ISOTime({ value });
  }
}
