import { ActionTree, GetterTree, MutationTree } from "vuex";
import { required, email } from "@vuelidate/validators";
import { login } from "@/services/user";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
const rulesToLogin = {
  username: { required, email },
  password: { required },
};

const getJwtCookie = () => {
  try {
    return Cookies.get("jwt");
  } catch (err) {
    return undefined;
  }
};
const state = { jwt: getJwtCookie() };
type State = typeof state;
const mutations = <MutationTree<State>>{
  setJwt(state, token: string) {
    state.jwt = token;
    Cookies.set("jwt", token, { path: "/" });
  },
  removeJwt(state) {
    state.jwt = undefined;
    Cookies.remove("jwt");
  },
};
const actions = <ActionTree<State, unknown>>{
  async LOGIN(
    { commit },
    { username, password }: { username: string; password: string }
  ) {
    const jwt = await login({ username, password });
    commit("setJwt", jwt);
    return true;
  },
  async LOGOUT({ commit }) {
    commit("removeJwt");
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToLogin: () => rulesToLogin,
  jwt: (state) => state.jwt,
  info: (state) => {
    const jwt = state.jwt;
    if (!jwt) return null;
    const decodedJwt = jwtDecode(jwt);
    return decodedJwt;
  },
  isAdmin: (_, getters) => {
    return !!getters["info"]?.isAdmin;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
