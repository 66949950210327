import { PosId, Space } from "@/types";
import axios from "./axios";

export const createSpace = (options: { posId: PosId; space: Space }) => {
  const { space, posId } = options;
  return axios
    .post(`/space`, {
      posId,
      name: space.getName(),
      tables: space.getTables().map((table) => {
        const tableToSend: any = {
          name: table.getName(),
          maximumCapacity: table.getMaximumCapacity(),
        };
        return tableToSend;
      }),
    })
    .then(() => {
      return true;
    });
};
export const updateSpace = (options: { posId: PosId; space: Space }) => {
  const { space, posId } = options;
  return axios
    .put(`/space/${space.getId()}`, {
      posId,
      name: space.getName(),
      tables: space.getTables().map((table) => {
        const tableToSend: any = {
          name: table.getName(),
          maximumCapacity: table.getMaximumCapacity(),
        };
        if (table.getId()) {
          tableToSend.id = table.getId();
        }
        return tableToSend;
      }),
    })
    .then(() => {
      return true;
    });
};
export const deleteSpace = (options: { posId: PosId; space: Space }) => {
  const { space, posId } = options;
  return axios
    .delete(`/space/${space.getId()}`, { params: { posId } })
    .then(() => {
      return true;
    });
};
