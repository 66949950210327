import {
  addReservation,
  cancelBooking,
  changeReservationDetails,
  confirmBooking,
  downloadReservations,
  getReservation,
  getReservations,
} from "@/services/reservation";
import {
  findErrorCode,
  Reservation,
  RESERVATION_STATUS,
  ServiceId,
  SpaceId,
} from "@/types";
import { cloneDeep } from "lodash";
import { ActionTree, GetterTree } from "vuex";
import { required, email, minValue } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { addDays, parseISO } from "date-fns";
import {
  DateTimeToISOString,
  dateToISO,
  isDateTimeInSlot,
} from "@/assets/utils/date";
import {
  countAttendees,
  countReservations,
  groupByDate,
} from "@/assets/utils/reservations";

import { phoneNumber } from "@/assets/utils/validators";
const rulesToCreateAReservation: any = {
  status: { required },
  channel: {},

  client: {
    email: { email },
    firstName: { required },
    lastName: { required },
    phoneNumber: { required, phoneNumber },
  },
  spaceId: {},
  tableIds: {},
  comment: {},
  teamMemberId: {},
  attendeesCount: { required },
  at: { required },
  amount: {
    minValue: minValue(0),
  },
};

const rulesToCreateANotifiableReservation = cloneDeep({
  ...rulesToCreateAReservation,
});
rulesToCreateANotifiableReservation.client.email = {
  emailRequiredToNotifyClient: required,
  email,
};

const rulesToUpdateAReservation = cloneDeep({
  id: { required },
  ...rulesToCreateAReservation,
});
const rulesToUpdateANotifiableReservation = cloneDeep({
  ...rulesToUpdateAReservation,
});
rulesToUpdateANotifiableReservation.client.email = {
  emailRequiredToNotifyClient: required,
  email,
};

const CreateReservationFromPayload = (payload: any) => {
  return new Reservation({
    id: payload.id,
    tableIds: payload.tableIds,
    status: payload.status,
    client: payload.client,
    attendeesCount: payload.attendeesCount,
    at: payload.at,
    spaceId: payload.spaceId,
    teamMemberId: payload.teamMemberId,
    comment: payload.comment,
    channel: payload.channel,
    amount: payload.amount,
  });
};

const state = {
  reservations: <any[]>[],
  reservationsFilter: {
    spaces: new Array<string>(),
    service: null as unknown as ServiceId,
    status: new Array<string>(),
  },
};
type State = typeof state;

// const mutations = <MutationTree<State>>(<unknown>{

// });

const actions = <ActionTree<State, unknown>>{
  async DOWNLOAD(
    { state, rootGetters }: any,
    { startDate, endDate }: { startDate: string; endDate: string }
  ) {
    const posId = rootGetters["pos/currentPosId"];
    downloadReservations({ startDate, endDate, posId });
  },
  async RETRIEVE(
    { state, rootGetters }: any,
    { startDate, endDate }: { startDate: string; endDate: string }
  ) {
    const posId = rootGetters["pos/currentPosId"];

    // Retrieve reservations for the given date range
    const reservations = await getReservations({
      startDate,
      endDate,
      posId,
    });
    // Get services that expand to next day
    const servicesExpandingToNextDay =
      rootGetters["pos/servicesExpandingToNextDay"] ?? [];

    // Check if any service expands to the next day
    const isAnyServiceExpandingToNextDay =
      servicesExpandingToNextDay.length > 0;
    let reservationsForNextDay: any = [];
    const endDatePlusOne = dateToISO({
      date: addDays(parseISO(endDate), 1),
      withHHMM: false,
    });
    const startDateMinusOne = dateToISO({
      date: addDays(parseISO(startDate), -1),
      withHHMM: false,
    });
    if (isAnyServiceExpandingToNextDay) {
      // If any service expands to the next day, retrieve reservations for the next day
      const endDatePlusOne = dateToISO({
        date: addDays(parseISO(endDate), 1),
        withHHMM: false,
      });

      reservationsForNextDay = await getReservations({
        startDate: endDatePlusOne,
        endDate: endDatePlusOne,
        posId,
      });
    }
    // console.log("store", rootGetters["pos/services"]);
    const result = groupByDate(
      [...cloneDeep(reservations), ...cloneDeep(reservationsForNextDay)],
      rootGetters["pos/services"]
    );
    // console.log("store", result);
    if (isAnyServiceExpandingToNextDay) {
      delete result[endDatePlusOne];
    }
    if (result[startDateMinusOne]) {
      delete result[startDateMinusOne];
    }

    return result;
  },
  async RETRIEVE_BY_ID({ state, rootGetters }: any, { id }: { id: string }) {
    const posId = rootGetters["pos/currentPosId"];

    // Retrieve reservations for the given date range
    const reservation = await getReservation({
      posId,
      id,
    });

    return reservation;
  },
  async CREATE_RESERVATION(
    { rootGetters },
    { reservation: reservationDto, notifyClient }: any
  ) {
    const rules = getters["rulesToCreateAReservation"];
    const v$ = useVuelidate(rules, reservationDto);
    const posId = rootGetters["pos/currentPosId"];
    const isReservationValid = await v$.value.$validate();
    if (!isReservationValid) {
      return;
    }

    try {
      const reservation = CreateReservationFromPayload(reservationDto);
      const createdReservationId = await addReservation({
        posId,
        reservation: reservation,
        notifyClient,
      });
      return createdReservationId;
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async CHANGE_RESERVATION_DETAILS(
    { rootGetters },
    { reservation: reservationDto, notifyClient }: any
  ) {
    const rules =
      getters[
        `reservation/rulesToUpdateA${
          notifyClient ? "Notifiable" : ""
        }Reservation`
      ];

    const v$ = useVuelidate(rules, reservationDto);
    const posId = rootGetters["pos/currentPosId"];
    const isReservationValid = await v$.value.$validate();
    console.log("isReservationValid", isReservationValid);
    if (!isReservationValid) {
      return;
    }

    try {
      const reservation = CreateReservationFromPayload(reservationDto);
      await changeReservationDetails({
        posId,
        reservation: reservation,
        notifyClient,
      });
      return true;
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async CANCEL_BOOKING(_, { id }: { id: string }) {
    try {
      await cancelBooking({
        id,
      });
      return true;
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async CONFIRM_BOOKING(_, { id }: { id: string }) {
    try {
      await confirmBooking({
        id,
      });
      return true;
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async APPLY_SPACE_FILTERS({ state }, payload: { spaceIds: SpaceId[] }) {
    state.reservationsFilter.spaces = payload.spaceIds;
  },
  async APPLY_SERVICE_FILTER({ state }, payload: { serviceId: ServiceId }) {
    state.reservationsFilter.service = payload.serviceId;
  },
  async APPLY_STATUS_FILTERS(
    { state },
    payload: { statusIds: RESERVATION_STATUS[] }
  ) {
    state.reservationsFilter.status = payload.statusIds;
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToCreateAReservation() {
    return rulesToCreateAReservation;
  },
  rulesToCreateANotifiableReservation() {
    return rulesToCreateANotifiableReservation;
  },
  rulesToUpdateAReservation() {
    return rulesToUpdateAReservation;
  },
  rulesToUpdateANotifiableReservation() {
    return rulesToUpdateANotifiableReservation;
  },
  reservations(state: State, getters: any, rootState: any, rootGetters: any) {
    console.trace("here");
    const calendarReservations: any = getters["calendarReservations"];
    let reservations: any = Object.values(calendarReservations).reduce(
      (acc: any, current: any) => [...acc, ...current.reservations],
      []
    );
    reservations = reservations.map((rawReservation: any) => {
      const space = rootGetters["pos/spaceById"](rawReservation.spaceId);
      const tables: any[] = [];
      (rawReservation.tableIds ?? []).map((tableId: any) => {
        const table = rootGetters["pos/tableById"](tableId);
        if (table) {
          tables.push(table);
        }
      });

      const teamMember = rootGetters["pos/teamMemberById"](
        rawReservation.teamMemberId
      );
      const reservation = {
        id: rawReservation.id,
        at: new Date(rawReservation.at),
        status: rawReservation.status as RESERVATION_STATUS,
        space: {
          id: space?.id,
          name: space?.name,
        },
        tables: tables,
        teamMember: {
          id: teamMember?.id,
          fullName: teamMember?.fullName,
        },
        client: {
          id: rawReservation.client?.id,
          firstName: rawReservation.client?.firstName,
          lastName: rawReservation.client?.lastName,
          email: rawReservation.client?.email,
          phoneNumber: rawReservation.client?.phoneNumber,
        },
        attendeesCount: rawReservation.attendeesCount,
        comment: rawReservation.comment,
      };
      return reservation;
    });
    return reservations;
  },
};

export default {
  namespaced: true,
  state,
  // mutations,
  actions,
  getters,
};
