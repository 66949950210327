import { getPos, getPosListOfTenant, updatePos } from "@/services/pos";
import { Pos, PosId } from "@/types";
import { cloneDeep } from "lodash";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { required } from "@vuelidate/validators";
import i18n from "@/i18n";
import Cookies from "js-cookie";
import useVuelidate from "@vuelidate/core";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const { t } = i18n.global;

const rulesToUpdateAPos = {
  name: { required },
  onlineName: { required },
};
const getCurrentPosIdCookie = () => {
  try {
    return Cookies.get("currentPosId");
  } catch (err) {
    return undefined;
  }
};
const state = {
  pos: undefined as Pos | undefined,
  currentPosId: getCurrentPosIdCookie(),
  posListOfTenant: null,
};
type State = typeof state;

const mutations = <MutationTree<State>>(<unknown>{
  setCurrentPosId(state: State, posId: PosId) {
    state.currentPosId = posId;
    if (posId) {
      Cookies.set("currentPosId", posId, { path: "/" });
    } else {
      Cookies.remove("currentPosId");
    }
  },
  removeCurrentPosId(state: State, posId: PosId) {
    state.currentPosId = posId;
    Cookies.remove("currentPosId");
  },
  setPosListOfTenant(state: State, posListOfTenant: any) {
    state.posListOfTenant = posListOfTenant;
  },
  setPos(state: State, pos: any) {
    state.pos = pos;
  },
});

const actions = <ActionTree<State, unknown>>{
  async RETRIEVE_TENANT_LIST({ commit }) {
    const result = await getPosListOfTenant();
    state.posListOfTenant = result;
    commit("setPosListOfTenant", result);
  },
  async RETRIEVE({ commit, getters, rootGetters }) {
    const result = await getPos({
      posId: getters["currentPosId"],
      tenantId: rootGetters["user/info"]?.tenantId,
    });
    commit("setPos", result);
  },
  async UPDATE_POS({ commit, getters }, payload: any) {
    const rules = getters["rulesToUpdateAPos"];
    const v$ = useVuelidate(rules, payload);
    const isPosValid = await v$.value.$validate();
    if (isPosValid) {
      await updatePos({
        pos: payload,
      });
    }
  },
  async SET_CURRENT_POS_ID({ commit }, payload: PosId) {
    commit("setCurrentPosId", payload);
  },
  async LOGOUT({ commit }) {
    commit("removeCurrentPosId");
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToUpdateAPos() {
    return rulesToUpdateAPos;
  },
  posListOfTenant(state: State) {
    return state.posListOfTenant;
  },
  pos(state: State) {
    return cloneDeep(state.pos);
  },
  // TODO: to remove one pos is retrieved
  currentPosId(state: State) {
    return state.currentPosId;
  },
  currentPos: (
    state: State,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    const currentPosId = getters["currentPosId"];
    return (getters["userAccess"] ?? []).find(
      (pos: any) => pos.id === currentPosId
    );
  },
  userAccess(state: State, getters: any, rootState: any, rootGetters: any) {
    return rootGetters["user/info"]?.posAccess ?? [];
  },
  displayableServices(state: State, getters: any) {
    const services = cloneDeep(getters["services"]);
    return cloneDeep(services);
  },
  services(state: State) {
    const services = cloneDeep(state.pos?.getServices() ?? []).map(
      (service) => ({
        id: service.getId(),
        name: service.getName(),
        startingAt: service.getStartingAt()?.value,
        endingAt: service.getEndingAt()?.value,
        duration: service.getDuration()?.value,
        isExpandingtoNextDay: service.isExpandingtoNextDay(),
        delayBetweenSlots: service.delayBetweenSlots?.value,
        spaces: service
          .getSpaces()
          .map((space) => ({ id: space.id, name: space.name })),
        onlineName: service.onlineName,
        averageDuration: service.averageDuration?.value,
        onlineAvailablePlaces: service.onlineAvailablePlaces,
        openDays: service.openDays,
      })
    );
    return services;
  },
  servicesExpandingToNextDay(state: State, getters: any) {
    return (getters["services"] ?? [])
      .filter((service: any) => service.isExpandingtoNextDay)
      .map((service: any) => {
        return service;
      });
  },
  spaces(state: State) {
    const spaces = cloneDeep(state.pos?.getSpaces() ?? []);
    return cloneDeep(
      spaces.map((space) => ({
        id: space.getId(),
        name: space.getName(),
        tables: space.getTables().map((table) => ({
          id: table.getId(),
          name: table.getName(),
          maximumCapacity: table.getMaximumCapacity(),
        })),
      }))
    );
  },
  spaceById: (state: State, getters: any) => (id: any) => {
    return (getters["spaces"] ?? []).find((space: any) => {
      return space.id === id;
    });
  },
  tableById: (state: State, getters: any) => (id: any) => {
    for (const obj of getters["spaces"] ?? []) {
      const table = obj.tables.find((t: any) => t.id === id);
      if (table) {
        return table;
      }
    }
    return null;
  },
  teamMembers(state: State) {
    const teamMembers = cloneDeep(state.pos?.getTeamMembers() ?? []);
    return cloneDeep(
      teamMembers.map((teamMember) => ({
        id: teamMember.getId(),
        fullName: teamMember.getFullName(),
      }))
    );
  },
  teamMemberById: (state: State, getters: any) => (id: any) => {
    return (getters["teamMembers"] ?? []).find((teamMember: any) => {
      return teamMember.id === id;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
