import {
  addClient,
  deleteClient,
  findClients,
  getClientById,
  listClients,
  updateClient,
} from "@/services/client";
import { Client, SUPPORTED_ERROR_CODES, findErrorCode } from "@/types";
import { phoneNumber } from "@/assets/utils/validators";
import useVuelidate from "@vuelidate/core";
import { helpers, required, email } from "@vuelidate/validators";

import { ActionTree, GetterTree } from "vuex";

const CreateClientFromPayload = (payload: any) => {
  return new Client({
    id: payload.id,
    firstName: payload.firstName,
    lastName: payload.lastName,
    phoneNumber: payload.phoneNumber,
    email: payload.email,
  });
};

const rulesToCreateAClient = {
  gender: {},
  firstName: { required },
  lastName: { required },
  phoneNumber: { required, phoneNumber: phoneNumber },
  email: { email },
};
const rulesToUpdateAClient = {
  id: { required },
  ...rulesToCreateAClient,
};
const rulesToCreateAOnlineClient = {
  gender: { required },
  firstName: { required },
  lastName: { required },
  phoneNumber: { required, phoneNumber: phoneNumber },
  email: {
    email,
    required,
  },
  comment: {},
};

const state = {};
type State = typeof state;

// const mutations = <MutationTree<State>>(<unknown>{

// });

const actions = <ActionTree<State, unknown>>{
  async CREATE_CLIENT({ getters, rootGetters }, payload: any) {
    const rules = getters["rulesToCreateAClient"];
    const tenantId = rootGetters["user/info"]?.tenantId;
    const v$ = useVuelidate(rules, payload);
    const isClientValid = await v$.value.$validate();

    if (!isClientValid) {
      return;
    }

    try {
      const client = CreateClientFromPayload(payload);
      const createdClientId = await addClient({ tenantId, client });
      return createdClientId;
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      return errorCode;
    }
  },
  async FIND_CLIENTS({ rootGetters }, payload: any) {
    const tenantId = rootGetters["user/info"]?.tenantId;
    try {
      const clients = await findClients({ tenantId, word: payload.word });
      return clients;
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      return errorCode;
    }
  },
  async LIST_CLIENTS({ rootGetters }, payload: any) {
    const tenantId = rootGetters["user/info"]?.tenantId;
    try {
      const clients = await listClients({
        tenantId,
        word: payload?.word,
        page: payload?.page,
      });
      return clients;
    } catch (e: any) {
      console.log(e);
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      return errorCode;
    }
  },
  async GET_CLIENT_BY_ID({ rootGetters }, payload: any) {
    const tenantId = rootGetters["user/info"]?.tenantId;
    const client = await getClientById({
      tenantId,
      clientId: payload.clientId,
    });
    return client;
  },
  async DELETE_CLIENT({ rootGetters, state }, payload: any) {
    try {
      await deleteClient({ id: payload?.id });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      throw errorCode;
    }
  },
  async CHANGE_DETAILS({ rootGetters, state }, payload: any) {
    try {
      const rules = getters[`reservation/rulesToUpdateAClient`];

      const v$ = useVuelidate(rules, payload);
      const tenantId = rootGetters["user/info"]?.tenantId;
      const isClientValid = await v$.value.$validate();
      if (isClientValid) {
        await updateClient({
          client: payload,
          tenantId,
        });
      } else {
        throw SUPPORTED_ERROR_CODES.INVALID_DATA;
      }
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      throw errorCode;
    }
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToCreateAClient() {
    return rulesToCreateAClient;
  },
  rulesToUpdateAClient() {
    return rulesToUpdateAClient;
  },
  rulesToCreateAOnlineClient() {
    return rulesToCreateAOnlineClient;
  },
};

export default {
  namespaced: true,
  state,
  // mutations,
  actions,
  getters,
};
