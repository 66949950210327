<template>
  <section class="max-w-screen-md mx-auto py-4 flex flex-col gap-4">
    <h1 class="text-4xl pb-4">BookOnline Privacy Policy</h1>
    <p>
      Welcome to BookOnline! We are committed to providing our users with a
      secure and reliable platform to book reservations for restaurants and
      other professionals.
    </p>
    <p>
      We understand that privacy is of the utmost importance when it comes to
      personal information. Therefore, we take the responsibility of protecting
      your personal information seriously. In accordance with our privacy
      policy, we would like to inform you of how we handle and store your
      information.
    </p>
    <h2>Information We Collect</h2>
    <p>
      When you create an account on BookOnline, we may collect your personal
      information such as your name, email address, phone number, and payment
      information. This information is used solely for the purpose of processing
      your reservations and providing you with an optimal user experience on our
      platform.
    </p>
    <h2>Use of Your Information</h2>
    <p>
      We may also use your personal information to send you promotional offers,
      updates, and other marketing communications about our services that may be
      of interest to you. If you do not wish to receive such communications, you
      may opt-out at any time by adjusting your account settings or by
      contacting our customer support team.
    </p>
    <p>
      Please note that we do not sell or rent your personal information to third
      parties for their marketing purposes without your explicit consent.
      However, we may share your personal information with third-party service
      providers who assist us in processing your reservations, handling
      payments, and providing customer support.
    </p>
    <h2>Security of Your Information</h2>
    <p>
      We take reasonable measures to protect your personal information from
      unauthorized access, disclosure, alteration, or destruction. Your
      information is stored on secure servers, and we have implemented physical,
      technical, and administrative safeguards to ensure the confidentiality and
      integrity of your information.
    </p>
    <h2>Consent to Our Privacy Policy</h2>
    <p>
      By using BookOnline, you consent to the storage and use of your personal
      information in accordance with this policy. If you have any questions or
      concerns regarding our privacy practices, please do not hesitate to
      contact us.
    </p>
    <p>
      Thank you for choosing BookOnline, and we look forward to providing you
      with a seamless reservation experience!
    </p>
  </section>
</template>
