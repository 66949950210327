import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import store from "./store";
import createI18n from "@/i18n";
import VCalendar from "v-calendar";
import "@/assets/tailwind.scss";
// import "primevue/resources/themes/tailwind-light/theme.css";
//import "primevue/resources/primevue.min.css";
import "@/assets/primevue.scss";
import "primeicons/primeicons.css";
import "v-calendar/dist/style.css";
import "@/assets/tailwind.scss";
import { getCurrentLanguagePreference } from "./assets/utils/GetCurrentLanguagePreference";
const currentLang = getCurrentLanguagePreference();
// eslint-disable-next-line @typescript-eslint/no-var-requires
const primevueTrads = require(`@/locales/primevue/${currentLang}.json`);
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Toast from "primevue/toast";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(createI18n)
  .use(PrimeVue, { locale: primevueTrads })
  .use(ToastService)
  .use(ConfirmationService)
  .use(VCalendar, {});

app.component("PToast", Toast);
app.mount("#app");
