import { createI18n } from "vue-i18n";
import en from "@/locales/en.json";
import fr from "@/locales/fr.json";
import Cookies from "js-cookie";
import { getCurrentLanguagePreference } from "./assets/utils/GetCurrentLanguagePreference";

const i18n = createI18n({
  legacy: false,
  locale: getCurrentLanguagePreference(),
  messages: { en, fr },
});

export default i18n;
