import { useToast } from "primevue/usetoast";
import { ComposerTranslation } from "vue-i18n";
export const useNotify = ({ t }: { t: ComposerTranslation }) => {
  const toast = useToast();
  const successToast = ({
    title,
    detail,
  }: {
    title: string;
    detail: string;
  }) => {
    toast.add({
      severity: "success",
      life: 3000,
      summary: title,
      detail: detail,
    });
  };
  const errorToast = ({ title, error }: { title: string; error: any }) => {
    const errorMessage = error
      ? `ERROR_CODE.${error}`
      : "ERROR_CODE.UNKNOWN_ERROR";
    toast.add({
      severity: "error",
      summary: title,
      detail: t(errorMessage),
    });
  };

  return {
    successToast,
    errorToast,
  };
};
