import { ISOTime } from "@/assets/utils/ISOTime";
import { timeHHMMvalidator } from "@/assets/utils/validators";
import {
  createService,
  deleteService,
  updateService,
} from "@/services/service";
import { findErrorCode, Service } from "@/types";
import useVuelidate from "@vuelidate/core";
import { helpers, required, minValue } from "@vuelidate/validators";
import { ActionTree, GetterTree } from "vuex";

const rulesToCreateAService = {
  name: {
    required,
  },
  startingAt: {
    required,
    timeHHMMvalidator,
  },
  endingAt: {
    required,
    timeHHMMvalidator,
  },
  onlineName: {
    required,
  },
  delayBetweenSlots: {
    required,
    timeHHMMvalidator,
  },
  averageDuration: {
    required,
    timeHHMMvalidator,
  },
  onlineAvailablePlaces: {
    required,
    minValue: minValue(0),
  },
  openDays: {
    required,
  },
};
const rulesToUpdateAService = {
  id: { required },
  ...rulesToCreateAService,
};

const state = {};
type State = typeof state;

// const mutations = <MutationTree<State>>(<unknown>{

// });

const actions = <ActionTree<State, unknown>>{
  async CREATE_SERVICE({ getters, rootGetters, state }, payload: any) {
    const rules = getters["rulesToCreateAService"];
    const posId = rootGetters["pos/currentPosId"];
    const v$ = useVuelidate(rules, payload);
    const isServiceValid = await v$.value.$validate();

    if (!isServiceValid) {
      return;
    }

    try {
      await createService({ posId, service: payload });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async UPDATE_SERVICE({ getters, rootGetters, state }, payload: any) {
    const rules = getters["rulesToUpdateAService"];
    const posId = rootGetters["pos/currentPosId"];
    const v$ = useVuelidate(rules, payload);
    const isServiceValid = await v$.value.$validate();
    if (!isServiceValid) {
      return;
    }

    try {
      await updateService({ posId, service: payload });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });

      throw new Error(errorCode);
    }
  },
  async DELETE_SERVICE({ rootGetters }, payload: any) {
    try {
      const posId = rootGetters["pos/currentPosId"];
      await deleteService({ posId, service: payload });
    } catch (e: any) {
      const errorCode: string = findErrorCode({
        errorCode: e.response?.data?.message,
      });
      throw new Error(errorCode);
    }
  },
};
const getters = <GetterTree<State, unknown>>{
  rulesToUpdateAService() {
    return rulesToUpdateAService;
  },
  rulesToCreateAService() {
    return rulesToCreateAService;
  },
};

export default {
  namespaced: true,
  state,
  // mutations,
  actions,
  getters,
};
