import { format } from "date-fns";

export const getMonthStartAndEnd = (
  month: number,
  year: number
): { startOfMonth: string; endOfMonth: string } => {
  const date = new Date(Date.UTC(year, month - 1, 1));
  const startOfMonth = date.toISOString().slice(0, 10);
  date.setUTCMonth(date.getUTCMonth() + 1);
  date.setUTCDate(date.getUTCDate() - 1);
  const endOfMonth = date.toISOString().slice(0, 10);
  return { startOfMonth, endOfMonth };
};

export const HHMMFromISO = (isoDate: string) => {
  const dateObj = new Date(isoDate);
  return format(dateObj, "dd-MM-yyyy HH:mm");
};

export const readableDate = ({ date, lang }: { date: Date; lang: string }) => {
  try {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    } as const;
    const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);
    return formattedDate;
  } catch (e) {
    return date;
  }
};
export function isGreater(time1: string, time2: string): boolean {
  const [hour1, minute1] = time1.split(":").map(Number);
  const [hour2, minute2] = time2.split(":").map(Number);

  const totalMinutes1 = hour1 * 60 + minute1;
  const totalMinutes2 = hour2 * 60 + minute2;

  return totalMinutes1 > totalMinutes2;
}

export const dateToISO = ({
  date,
  withHHMM = true,
}: {
  date: Date;
  withHHMM?: boolean;
}) => {
  return format(date, withHHMM ? "yyyy-MM-dd'T'HH:mm" : "yyyy-MM-dd");
};
export const DateToISOString = (date: Date) =>
  dateToISO({ date, withHHMM: true });
export const DateTimeToISOString = (date: Date) =>
  dateToISO({ date, withHHMM: false });

export const isDateTimeInSlot = (
  dateTime: Date,
  timeSlot: { startingAt: string; endingAt: string }
): boolean => {
  const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
  const [startHour, startMinute] = timeSlot.startingAt.split(":").map(Number);
  const [endHour, endMinute] = timeSlot.endingAt.split(":").map(Number);

  const startDate = new Date(date);
  startDate.setHours(startHour, startMinute);

  const endDate = new Date(date);
  endDate.setHours(endHour, endMinute);

  if (startDate > endDate) {
    // If the end time is before the start time, it means the slot spans over midnight
    if (date >= startDate) {
      endDate.setDate(endDate.getDate() + 1);
    } else {
      startDate.setDate(startDate.getDate() - 1);
    }
  }

  return date >= startDate && date <= endDate;
};
