import { createStore } from "vuex";
import reservation from "./modules/reservation";
import pos from "./modules/pos";
import space from "./modules/space";
import client from "./modules/client";
import service from "./modules/service";
import teamMember from "./modules/teamMember";
import online from "./modules/online";
import user from "./modules/user";
import app from "./modules/app";
import tenant from "./modules/tenant";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    tenant,
    reservation,
    pos,
    service,
    client,
    space,
    teamMember,
    online,
    user,
  },
});
